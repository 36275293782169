import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState
} from 'react';

import styled from 'styled-components';

type Ladder = [clue: string, answer: string][]

type State = {
  ladder: Ladder
  answers: Set<string>
  answered: Set<string>
  started?: Date
  finished?: Date
}

const normalize = (value: string) => value.trim().toLowerCase();

const initialize = (ladder: Ladder): State => ({
  ladder,
  answers: new Set(ladder.map(([_, answer]) => normalize(answer))),
  answered: new Set()
});

type Action =
  { type: 'start' } |
  { type: 'guess', answer: string }

const reducer = (state: State, action: Action): State => {
  if (action.type === 'start') {
    return {
      ...state,
      started: new Date()
    };
  }

  const answer = normalize(action.answer);
  
  const {
    answers,
    answered
  } = state;
  
  if (answers.has(answer)) {
    const nextState = { ...state };

    nextState.answered = new Set([...answered, answer]);

    if (nextState.answered.size === answers.size) {
      nextState.finished = new Date();
    }

    return nextState;
  }

  return state;
};

const Timer = ({ start, active }: { start: Date, active?: boolean }) => {
  let [counter, set] = useState(0);

  useEffect(() => {
    if (active) {
      const update = () => set(Math.floor((+new Date() - +start) / 100));

      const timer = setInterval(update, 100);

      update();

      return () => clearInterval(timer);
    }
  }, [
    start,
    active
  ]);

  const tenths = counter % 10;
  
  counter -= tenths;
  counter /= 10;
  
  const seconds = counter % 60;
  const minutes = Math.floor(counter / 60);

  return <Styled.Time>
    {`${minutes}`.padStart(2, '0')}:{`${seconds}`.padStart(2, '0')}.{tenths}
  </Styled.Time>;
};

const LadderItem = styled.li`
  margin: 0 0 25px 50px;
  padding: 10px;
  border: 1px solid;
  width: calc((100vw - 250px) / 3);
`;

const Styled = {
  Container: styled.section`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
  `,

  Header: styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 0 10px #555;
  `,

  Placeholder: styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font-size: 50px;
  `,

  AnswerInput: styled.input.attrs({
    placeholder: 'Answer'
  })`
    flex-grow: 1;
    padding: 20px;
    text-align: center;
    font-size: 30px;

    border: none;
    outline: none;

    &:disabled {
      visibility: hidden;
    }
  `,

  Ladder: styled.ol`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    overflow: hidden;

    list-style-type: none;
    margin: 0;
    padding: 50px 50px 50px 0;
  `,

  LadderItem,

  LadderItemClue: styled(LadderItem)``,

  LadderItemAnswer: styled(LadderItem)`
    background: green;
    color: white;
    font-weight: bold;
  `,

  Time: styled.time`
    width: max(10vw, 100px);
    text-align: right;
    margin-right: 25px;
    font-family: monospace;
    font-size: 25px;
  `
};

const Main = (props: { ladder: Ladder }) => {
  const { ladder } = props;

  const [state, dispatch] = useReducer(reducer, ladder, initialize);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'guess',
      answer: event.target.value
    });
  };

  const start = () => dispatch({ type: 'start' });

  const answerInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    answerInputRef.current!.value = '';
  }, [state.answered]);

  useLayoutEffect(() => {
    if (state.started) {
      answerInputRef.current!.focus();
    }
  }, [state.started]);

  const content = state.started ? (
    <Styled.Ladder>
      {ladder.map(([clue, answer], n) => (
        state.answered.has(answer) ? (
          <Styled.LadderItemAnswer key={n}>
            {answer}
          </Styled.LadderItemAnswer>
        ) : (
          <Styled.LadderItemClue key={n}>
            {clue}
          </Styled.LadderItemClue>
        )
      ))}
    </Styled.Ladder>
  ) : (
    <Styled.Placeholder onClick={start}>
      Click to start
    </Styled.Placeholder>
  )

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.AnswerInput
          ref={answerInputRef}
          disabled={!!state.finished}
          onChange={onChange}
        />

        <Timer
          start={state.started || new Date()}
          active={state.started && !state.finished}
        />
      </Styled.Header>

      {content}
    </Styled.Container>
  );
}

const first: Ladder = [
  ['UAE national', 'arab'],
  ['Lacking colour', 'drab'],
  ['Snatching movement', 'grab'],
  ['Small insect', 'grub'],
  ['Water sound', 'glub'],
  ['Botched or bungled', 'flub'],
  ['Eject saliva', 'flob'],
  ['Lazy and slovenly', 'slob'],
  ['Stuck up', 'snob'],
  ['Ignore', 'snub'],
  ['Warm & cozy', 'snug'],
  ['Terrestrial mollusc', 'slug'],
  ['Waste metal', 'slag'],
  ['Concrete section', 'slab'],
  ['Q-Tip', 'swab'],
  ['Incline peak', 'snab'],
  ['Violent attack', 'stab'],
  ['Strike worker', 'scab']
];

function parse(input: string): Ladder {
  return input.split('\n').map((line) => line.trim().split(': ').reverse()) as Ladder;
}

const second = parse(
  `sect: Religious group
  sept: September
  wept: Cried
  weft: Woven threads
  west: Increasing longitude
  rest: Take a break
  nest: Bird's home
  newt: Salamander
  next: Clothing retailer
  vext: Annoyed
  vent: Gas outlet
  tent: Under canvas
  went: Gone
  kent: English county
  kens: > 1 Kenneth
  fens: Marshlands
  feds: FBI agents
  beds: Flower locations
  leds: Efficient lights
  meds: Prescription drugs`
);

const ladders: { [name: string]: Ladder | undefined } = {
  first,
  second
};

const ladder = ladders[window.location.pathname.slice(1)] || first;

const App = () => {
  return <Main ladder={ladder} />;
};

export default App;
